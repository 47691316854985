import Col from "antd/es/col";
import Row from "antd/es/row";
import { createUseStyles } from "react-jss";

import MainBanner from "../components/MainBanner";
import FAQs from "components/sections/FAQs";
import Testimoinials from "components/sections/Testimoinials";
import HowDoesItWork from "components/sections/HowDoesItWork";
import WhyUseRaseet from "components/sections/WhyUseRaseet";
import Stats from "components/sections/Stats";
import FindUsAt from "components/sections/FindUsAt";
import ContactUs from "components/sections/ContactUs";
import WhatIsRaseet from "components/sections/WhatIsRaseet";
import { useEffect } from "react";

export default function HomePage() {
  const classes = useStyles();

  return (
    <div>
      <MainBanner />
      <div className={classes.pageContent}>
        <Stats />
        <WhatIsRaseet />
        <WhyUseRaseet />
        <HowDoesItWork />
        <Testimoinials />
        <FAQs />

        <Row
          gutter={50}
          onLoad={() => {
            if (window.location.hash === "#contact-us") {
              document.getElementById("contact-us")?.scrollIntoView();
            }
          }}
        >
          <Col xs={24} lg={12}>
            <FindUsAt />
          </Col>
          <Col xs={24} lg={12}>
            <div id="contact-us" />
            <ContactUs />
          </Col>
        </Row>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  pageContent: {
    width: "100vw",
    background: "#F5F7FA",
    overflow: "hidden",
  },

  "@media (min-width: 768px)": {
    pageContent: {
      width: "80vw",
      margin: "0 auto",
      padding: [50, 35],
    },
  },
}));
