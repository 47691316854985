import Button from "antd/es/button";
import Drawer from "antd/es/drawer";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { MenuOutlined } from "@ant-design/icons";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { useState } from "react";
import RaseetLogo from "../assets/images/raseet-footer-logo.png";

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const screen = useBreakpoint();
  const isLG = screen.lg;
  const classes = useStyles();

  const NavOptions = () => (
    <>
      <a href="/#why-use-raseet" className={classes.navOption}>
        Why use Raseet ?
      </a>
      <Link to="/about-us" className={classes.navOption}>
        About us
      </Link>
      <Link to="/frequently-asked-questions" className={classes.navOption}>
        FAQs
      </Link>
      <a href="https://app.raseet.com/retail/login">
        <Button type="default" className={classes.loginButton}>
          Login
        </Button>
      </a>
    </>
  );

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen);
  }

  return (
    <div className={classes.header}>
      <Link to="/">
        <img
          src={RaseetLogo}
          className={classes.headerLogo}
          alt="Raseet logo"
        />
      </Link>

      {isLG ? (
        <div className={classes.navOptionsWrapper}>
          <NavOptions />
        </div>
      ) : (
        <>
          <Button
            shape="circle"
            size="large"
            type="text"
            onClick={toggleDrawer}
            icon={<MenuOutlined />}
            className={classes.drawerToggler}
          />
          <Drawer
            placement="right"
            width={250}
            visible={drawerOpen}
            className={classes.drawer}
            onClose={toggleDrawer}
          >
            <NavOptions />
          </Drawer>
        </>
      )}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  header: {
    height: 70,
    padding: [0, 20],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "linear-gradient(276.86deg, #0081BD 7.44%, #236887 97.27%)",
  },
  headerLogo: {
    width: 140,
    objectFit: "contain",
  },
  navOptionsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  navOption: {
    padding: [10, 15],
    display: "block",
    fontStyle: "normal",
    fontFamily: "'Roboto'",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.03em",
    color: "#f7f7f7 !important",
    cursor: "pointer",

    "&:hover": {
      opacity: 0.6,
    },
  },
  loginButton: {
    width: 110,
    marginLeft: 10,
    fontFamily: "'Roboto'",
    letterSpacing: "1px",
    fontSize: "12px",
    background: "transparent !important",
    color: "#f7f7f7",
    borderColor: "#f7f7f7",

    "&:hover": {
      opacity: 0.9,
    },
  },
  drawer: {
    "& $navOption": {
      color: "#505050 !important",
    },

    "& .ant-drawer-body": {
      paddingTop: 60,
    },
    "& $loginButton": {
      marginTop: 20,
    },
  },
  drawerToggler: {
    border: "none",
    background: "transparent",

    "& .anticon": {
      fontSize: 20,
      color: "#f7f7f7",
    },
  },

  "@media (min-width: 768px)": {
    header: {
      height: 120,
      padding: [0, "10vw"],
    },
    headerLogo: {
      width: 180,
    },
    drawerToggler: {
      "& .anticon": {
        fontSize: 30,
      },
    },
  },
  "@media (min-width: 998px)": {
    header: {
      height: 100,
      padding: [0, "20vw"],
    },
    headerLogo: {
      width: 150,
    },
    drawerToggler: {
      "& .anticon": {
        fontSize: 25,
      },
    },
  },
}));

export default Header;
