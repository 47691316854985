import { Col, Row } from "antd";
import { createUseStyles } from "react-jss";
import GovtLogo from "assets/images/govt-logo.png";
import StartupIndiaLogo from "assets/images/startup-india-logo.png";

export default function Footer() {
  const classes = useStyles();

  return (
    <div>
      <Row justify="center" className={classes.footerContainer}>
        <Col xs={24} md={12} xl={5}>
          <div>
            <div className={classes.footerSectionTitle}>Product</div>
            <a href="/#what-is-raseet">
              <div className={classes.footerLink}>What is Raseet</div>
            </a>
            <a href="/#why-use-raseet">
              <div className={classes.footerLink}>Why use Raseet</div>
            </a>
            <a href="/frequently-asked-questions">
              <div className={classes.footerLink}>FAQs</div>
            </a>
          </div>
        </Col>
        <Col xs={24} md={12} xl={5}>
          <div>
            <div className={classes.footerSectionTitle}>Company</div>
            <a href="/about-us">
              <div className={classes.footerLink}>About Us</div>
            </a>
            <a href="/about-us#our-mission">
              <div className={classes.footerLink}>Our Mission</div>
            </a>
            <a href="/about-us#our-vision">
              <div className={classes.footerLink}>Our Vision</div>
            </a>
            <a href="/about-us#our-team">
              <div className={classes.footerLink}>Our Team</div>
            </a>
            <a href="/terms-and-conditions">
              <div className={classes.footerLink}>Terms and Conditions</div>
            </a>
            <a href="/privacy-policy">
              <div className={classes.footerLink}>Privacy Policy</div>
            </a>
          </div>
        </Col>
        <Col xs={24} md={12} xl={5}>
          <div>
            <div className={classes.footerSectionTitle}>Resources</div>
            <a href="https://blog.raseet.com">
              <div className={classes.footerLink}>Blogs</div>
            </a>
            <a href="https://youtube.com/channel/UCUFe6SlrMadOqkP49PY4rDw">
              <div className={classes.footerLink}>Videos</div>
            </a>
          </div>
        </Col>
        <Col xs={24} md={12} xl={5}>
          <div>
            <div className={classes.footerSectionTitle}>Contact Us</div>
            <a
              href="https://maps.app.goo.gl/65o2tdiFpp53N16TA"
              className={classes.footerLink}
            >
              <div className={classes.footerLink}>
                Kryptonas Innovations Private Limited SD-06, 2nd Floor, Sector
                116, Noida, UP - 201306
              </div>
            </a>
            <a href="tel:+919971199351">
              <div className={classes.footerLink}>+919971199351</div>
            </a>
            <a href="mailto:hello@raseet.com">
              <div className={classes.footerLink}>hello@raseet.com</div>
            </a>
          </div>
        </Col>
      </Row>

      <div className={classes.footerBottom}>
        <img
          width={150}
          src={GovtLogo}
          alt="Department of promotion of industry and internal trade"
        />
        <img
          width={120}
          src={StartupIndiaLogo}
          alt="Department of promotion of industry and internal trade"
        />
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  footerContainer: {
    padding: 20,
    background: "linear-gradient(93.36deg, #0081BD 12.42%, #236887 80.77%)",

    "& .ant-col": {
      padding: 20,
      display: "flex",
      // justifyContent: "center",
    },
  },
  footerSectionTitle: {
    width: "fit-content",
    fontSize: 28,
    fontFamily: "Gotham Medium",
    color: colors.light100,
    borderBottom: "2px solid #fff",
  },
  footerLink: {
    fontFamily: "Gotham Light",
    margin: [20, 0],
    cursor: "pointer",
    color: "#ffffff",
    fontSize: 16,

    "&:hover": {
      opacity: 0.8,
    },
  },
  footerBottom: {
    padding: [20, 0],
    border: "none",
    borderTop: "1.5px solid #fff",
    background: "linear-gradient(93.36deg, #0081BD 12.42%, #236887 80.77%)",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > *": {
      margin: [0, 20],
    },
  },
}));
