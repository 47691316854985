import Col from "antd/es/col";
import Row from "antd/es/row";
import Typography from "antd/es/typography";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import ContentCard from "components/ContentCard";
import { HowDoesItWorkData } from "data/ContentData";
import { createUseStyles } from "react-jss";

export default function HowDoesItWork() {
  const classes = useStyles();
  const isLG = useBreakpoint().lg;

  return (
    <ContentCard title="How Does It Work?">
      <div className={classes.gridContainer}>
        {HowDoesItWorkData.map((item, index) => (
          <Row
            key={index}
            className={classes.gridInfoLarge}
            style={{
              flexDirection: index % 2 !== 0 && isLG ? "row-reverse" : "row",
            }}
          >
            <Col xs={24} md={12} lg={10}>
              <img
                src={item.img}
                alt={item.title}
                className={classes.gridInfoLargeImg}
              />
            </Col>
            <Col
              xs={24}
              lg={10}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography.Title
                level={1}
                className={classes.gridInfoLargeTitle}
              >
                {item.title}
              </Typography.Title>
              <Typography.Paragraph className={classes.gridInfoContent}>
                {item.content}
              </Typography.Paragraph>
            </Col>
          </Row>
        ))}
      </div>
    </ContentCard>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  gridContainer: { padding: [30, 0, 0], marginBottom: -50 },
  gridInfoContent: {
    fontFamily: "Gotham Light",
    fontSize: 16,
    textAlign: "justify",
    lineHeight: "20px",
  },
  gridInfoLarge: {
    justifyContent: "center",
    margin: [30, 0, 0],

    "& > *": {
      padding: [10],
    },
  },
  gridInfoLargeTitle: {
    fontFamily: "Gotham Medium",
    lineHeight: "53px",
    color: "#236887 !important",
  },
  gridInfoLargeImg: {
    maxHeight: 400,
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },

  "@media (min-width: 768px)": {
    gridContainer: {},
    gridInfoLarge: {
      justifyContent: "flex-start",
      margin: 0,

      "& > *": {
        padding: [15, 20],
      },
      "&:first-child": {
        marginBottom: -40,
      },
    },
  },
}));
