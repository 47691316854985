import { Card, CardProps, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import { createUseStyles } from "react-jss";

interface ContentCardProps extends CardProps {
  title: string;
  titleCenter?: boolean;
  children: React.ReactNode;
}

export default function ContentCard({
  title,
  children,
  titleCenter = false,
  ...props
}: ContentCardProps) {
  const classes = useStyles({ titleCenter });
  const isMD = useBreakpoint().md;

  return (
    <Card {...props} className={`${classes.contentCard} ${props?.className}`}>
      <Typography.Title
        level={isMD ? 1 : 2}
        className={classes.contentCardTitle}
        style={{ textAlign: titleCenter ? "center" : "left" }}
      >
        {title}
      </Typography.Title>

      {children}
    </Card>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  contentCard: {
    padding: [15, 0, 5],
    marginBottom: 50,
    background: colors.light100,
  },
  contentCardTitle: {
    color: "#236887 !important",
    fontFamily: "Gotham Bold",
    textAlign: ({ titleCenter }) => (titleCenter ? "center" : "left"),
  },
  "@media (min-width: 768px)": {
    contentCard: {
      padding: [40, 10, 20],
      marginBottom: 100,
      borderRadius: "1.5rem",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
}));
