interface ErrorMessageProps {
  message: any;
  className: any;
}

export default function ErrorMessage({
  message = "",
  ...props
}: ErrorMessageProps) {
  return <div {...props}>{message}</div>;
}
