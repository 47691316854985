import axios from "axios";

type submitContactUsDataType = {
  name: string;
  number: string;
  email: string;
  address: string;
  subject: string;
  message: string;
};

export default async function SubmitContactUs(data: submitContactUsDataType) {
  return await axios.post(
    "https://pagcd7s9md.execute-api.ap-south-1.amazonaws.com/default/send-mail-website-input",
    data,
    {
      headers: {
        "Content-type": "application/json; charset=utf-8",
        "x-api-key": "9OvsRPph281TqJZsKRSMe2EmLn0BkY2688YP9XBj",
      },
    }
  );
}
