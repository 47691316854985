import { Button, Col, Input, Row, Typography } from "antd";
import { createUseStyles } from "react-jss";
import SavingIllustration from "assets/icons/icons-15-1.svg";
import { useState } from "react";
import hideNumberInputArrows from "style/hideNumberInputArrows";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const addCommas = (value: any) => {
  return value.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
  });
};

export default function CheckSavingsPage() {
  const classes = useStyles();
  const history = useHistory();
  const isMD = useBreakpoint().md;
  const [billPrintsCount, setBillPrintsCount] = useState(0);
  const [savings, setSavings] = useState({
    per_day: 0,
    per_year: 0,
  });

  const handleInputChange = (e: any) => setBillPrintsCount(e.target.value);

  const handleCalculateSavings = () => {
    setSavings({
      per_day: billPrintsCount * 0.5,
      per_year: billPrintsCount * 0.5 * 365,
    });
  };

  return (
    <div className={classes.checkSavingsPage}>
      <div className={classes.checkSavingsPageInner}>
        <Button
          type="text"
          size="large"
          icon={<ArrowLeftOutlined />}
          onClick={history.goBack}
          style={{ marginBottom: 20 }}
        />
        <Typography.Title level={isMD ? 1 : 2} className={classes.pageTitle}>
          Check Your Savings
        </Typography.Title>

        <Row gutter={[20, 20]}>
          <Col xs={24} md={12}>
            <img
              src={SavingIllustration}
              alt="savings illustration"
              className={classes.illustration}
            />
          </Col>
          <Col xs={24} md={12} className={classes.pageRight}>
            <Typography.Text>
              How many bills do you print in a day?
            </Typography.Text>

            <div className={classes.printInputWrapper}>
              <Input type="number" onChange={handleInputChange} />
              <Button type="link" onClick={handleCalculateSavings}>
                Calculate
              </Button>
            </div>

            <Typography.Text>You will save</Typography.Text>
            <div className={classes.calcItemWrapper}>
              <Typography.Text strong>
                ₹ {addCommas(savings.per_day)}
              </Typography.Text>
              <Typography.Text>Per day</Typography.Text>
            </div>
            <div className={classes.calcItemWrapper}>
              <Typography.Text strong>
                ₹ {addCommas(savings.per_year)}
              </Typography.Text>
              <Typography.Text>Per year</Typography.Text>
            </div>

            <Typography.Text className={classes.ifYouUseRaseetText}>
              If you use <strong>Raseet</strong>
            </Typography.Text>
          </Col>
        </Row>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  checkSavingsPage: {
    background: "#F5F7FA",
  },
  checkSavingsPageInner: {
    padding: [20, 30, 50],
    background: colors.light100,
  },
  pageTitle: {
    paddingBottom: 40,
    fontFamily: "Gotham Bold",
    color: "#236887 !important",
  },
  illustration: {
    marginBottom: 20,
    height: 250,
    objectFit: "contain",
  },
  pageRight: {
    fontSize: 16,
    fontFamily: "'Roboto'",

    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },

  printInputWrapper: {
    margin: [15, 0, 50],

    display: "flex",
    alignItems: "center",

    "& .ant-input": {
      width: 170,
    },
    ...hideNumberInputArrows,
  },
  calcItemWrapper: {
    width: 170,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > .ant-typography:nth-child(2)": {
      width: 100,
    },
  },
  ifYouUseRaseetText: {
    marginTop: 50,
    fontSize: 20,

    "& strong": {
      fontSize: 24,
      color: "#236887 !important",
    },
  },

  "@media (min-width: 998px)": {
    illustration: {
      height: 400,
    },
  },
  "@media (min-width: 768px)": {
    checkSavingsPage: {
      width: "80vw",
      margin: "0 auto",
      padding: [0, 35],
    },
    checkSavingsPageInner: {
      padding: [50, 70],
    },
    illustration: {
      margin: 0,
      width: "100%",
      height: 300,
    },
  },
}));
