import Col from "antd/es/col";
import Row from "antd/es/row";
import Button from "antd/es/button";
import ManWithPhone from "assets/images/man-with-phone.png";
import { createUseStyles } from "react-jss";

export default function MainBanner() {
  const classes = useStyles();

  return (
    <Row className={classes.mainBanner}>
      <Col xs={24} lg={12}>
        <div className={classes.bannerTextWrapper}>
          <div className={classes.bannerText}>
            Digitising &#38; evolving pharmacies as a one-stop-shop for their
            customers' complete health and wellness needs
          </div>
          <a
            href="https://bvzwnno4cf1.typeform.com/to/yfqRRI07"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              type="primary"
              size="large"
              className={classes.bannerButton}
            >
              Get started
            </Button>
          </a>
        </div>
      </Col>

      <Col xs={24} lg={12}>
        <img
          src={ManWithPhone}
          alt="Man with phone"
          className={classes.bannerImage}
        />
      </Col>
    </Row>
  );
}

const useStyles = createUseStyles({
  mainBanner: {
    // minHeight: "60vh",
    background: "linear-gradient(276.86deg, #0081BD 7.44%, #236887 97.27%)",
    padding: ["5vh", "10vw"],

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  bannerTextWrapper: {
    flex: 1,
    zIndex: 2,
  },
  bannerText: {
    fontFamily: "Gotham Bold",
    color: "#fff",
    fontSize: 22,
  },
  bannerButton: {
    marginTop: 20,
    background: "transparent",
    color: "#f7f7f7",
    borderColor: "#f7f7f7",
  },
  bannerImage: {
    width: "100%",
    objectFit: "contain",
  },
  "@media (min-width: 768px)": {
    bannerText: {
      fontSize: 28,
    },
  },
  "@media (min-width: 1440px)": {
    bannerText: {
      fontSize: 30,
    },
  },
  "@media (min-width: 1024px)": {
    mainBanner: {
      padding: ["5vh", "20vw"],
    },
    bannerTextWrapper: {
      // position: "absolute",
      // maxWidth: 450,
      // left: "20vw",
    },
    bannerImage: {
      height: 450,
    },
  },
});
