import Button from "antd/es/button";
import CollapsibleQuestion from "components/CollapsibleQuestion";
import ContentCard from "components/ContentCard";
import { FrequentlyAskedQuestions } from "data/ContentData";
import { CSSProperties } from "react";

interface FAQsProps {
  expanded?: boolean;
  cardStyles?: CSSProperties;
}

export default function FAQs({ expanded = false, cardStyles = {} }: FAQsProps) {
  return (
    <ContentCard title="Frequently Asked Questions" style={cardStyles}>
      {FrequentlyAskedQuestions.slice(0, expanded ? 20 : 4).map(
        (item, index) => (
          <CollapsibleQuestion {...item} key={index} />
        )
      )}

      {!expanded && (
        <a href="/frequently-asked-questions">
          <Button block type="link" size="large" style={{ marginTop: 20 }}>
            View more
          </Button>
        </a>
      )}
    </ContentCard>
  );
}
