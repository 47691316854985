import { Switch, Route } from "react-router-dom";
import "antd/dist/antd.min.css";
import "./App.css";
import HomePage from "./pages/HomePage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndCondtions from "./pages/TermsAndCondtions";
import Header from "components/Header";
import Footer from "components/Footer";
import AboutUs from "pages/AboutUs";
import FAQsPage from "pages/FAQsPage";
import CheckSavingsPage from "pages/CheckSavingsPage";

function App() {
  return (
    <>
      <Header />
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/about-us" exact component={AboutUs} />
        <Route path="/frequently-asked-questions" exact component={FAQsPage} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-and-conditions" component={TermsAndCondtions} />
        <Route path="/check-your-savings" component={CheckSavingsPage} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
