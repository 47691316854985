import { StatsData } from "data/ContentData";
import { createUseStyles } from "react-jss";

export default function Stats() {
  const classes = useStyles();

  return (
    <div className={classes.statsWrapper}>
      {StatsData.map((item, index) => (
        <div key={index} className={classes.statContainer}>
          <div className={classes.statTitle}>{item.title}</div>
          <div className={classes.statSubtitle}>{item.subtitle}</div>
        </div>
      ))}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  statsWrapper: {
    padding: [30, 0, 70],

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  statContainer: {
    width: "50%",
    padding: [20, 20],
    textAlign: "center",
  },
  statTitle: {
    color: "#236887",
    fontSize: 32,
    fontFamily: "Gotham Bold",
    whiteSpace: "nowrap",
  },
  statSubtitle: {
    fontSize: 18,
    fontFamily: "Gotham Light",
  },
  "@media (min-width: 768px)": {
    statsWrapper: {
      justifyContent: "space-evenly",
    },
    statContainer: {
      width: "33.3%",
    },
  },
  "@media (min-width: 998px)": {
    statsWrapper: {
      padding: [50, 0, 70],
      flexWrap: "nowrap",
    },
    statContainer: {
      width: "auto",
    },
  },
}));
