import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

function TermsAndCondtions() {
  const history = useHistory();
  const classes = useStyles();

  const Section = ({ title, children }: any) => (
    <div className={classes.pageSection}>
      <div className={classes.sectionTitle}>{title}</div>
      <div className={classes.sectionContent}>{children}</div>
    </div>
  );

  return (
    <div className={classes.termsAndCondtionsPage}>
      <Row className={classes.header}>
        <Col className={classes.backButton} span={3}>
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </Col>
        <Col className={classes.pageHeader} span={20}>
          Terms And Conditions
        </Col>
      </Row>
      <div className={classes.pageContent}>
        <Section title="Introduction">
          Thanks for using Raseet's products, services, websites, and apps which
          are branded as Raseet (Kryptonas Innovations Private Limited). These
          Terms of Use ("TOU") contain the terms under which Raseet and its
          affiliates provide their services to you and describe how the Services
          may be accessed and used. These TOU do not apply to Raseet Services
          which are available solely through our sales channel. Raseet provides
          a variety of different services. Additional service-specific terms and
          policies (including rules and guidelines) apply to some Services
          ("Additional Terms"). Those Additional Terms become a part of your
          agreement with us if you use those Services.
          <br /> We refer to the combination of these TOU and any applicable
          Additional Terms collectively as these "Terms". You indicate your
          agreement to these Terms by clicking or tapping on a button indicating
          your acceptance of these Terms, by executing a document that
          references them, or by using the Services. If you will be using the
          Services on behalf of an organization, you agree to these Terms on
          behalf of that organization and you represent that you have the
          authority to do so. In such a case, "you" and "your" will refer to
          that organization.
        </Section>
        <Section title="Description Of Service">
          Raseet, Inc. (Raseet, “we”, “us” or “our”) provides a platform via its
          website and apps (the "Site/App") to a community of registered users
          ("users" or "you") to engage in a variety of activities, including to
          upload and display bill ("Bill Content"), share comments, opinions,
          and ideas, feedback, participate in loyalty and promotions, register
          for premium membership accounts ("Premium Accounts"), and Bill Content
          through our distributors (individually and collectively, the
          "Services"). The foregoing list of Services is not all-inclusive and
          additional Services may be offered by us from time to time. The
          following are the terms of use ("Terms") for using the Site and the
          Services.
        </Section>
        <Section title="Acceptance of Terms">
          BY USING THE SERVICES, YOU ARE AGREEING, ON BEHALF OF YOURSELF AND
          THOSE YOU REPRESENT, TO COMPLY WITH AND BE LEGALLY BOUND BY THESE
          TERMS AS WELL AS OUR PRIVACY POLICY AND ALL APPLICABLE LAWS. IF YOU,
          FOR YOURSELF OR ON BEHALF OF THOSE YOU REPRESENT, DO NOT AGREE TO ANY
          PROVISION OF THESE TERMS, YOU MUST, FOR YOURSELF AND ON BEHALF OF ANY
          SUCH PERSON(S), DISCONTINUE THE REGISTRATION PROCESS, DISCONTINUE YOUR
          USE OF THE SERVICES, AND IF YOU ARE ALREADY REGISTERED, CANCEL YOUR
          ACCOUNT.
        </Section>
        <Section title="Modification of Terms">
          Raseet reserves the right, at its sole discretion, to modify or
          replace the Terms at any time. If the alterations constitute a
          material change to the Terms, Raseet will notify you by posting an
          announcement on the Site. What constitutes a material change will be
          determined at Raseet's sole discretion. You are responsible for
          reviewing and becoming familiar with any such modifications. Using any
          service or viewing any Bill Content constitutes your acceptance of the
          Terms as modified. <br />
          In addition, when using particular features of the Services, you are
          subject to any posted guidelines, terms or rules applicable to such
          Services.
          <br />
          Your access to and use of the Site and our Services may be interrupted
          from time to time as a result of equipment malfunction, updating,
          maintenance or repair of the Site or any other reason within or
          outside of our control. Raseet reserves the right to suspend or
          discontinue the availability of the Site, any Service, and to remove
          any Bill Content at any time at its sole discretion and without prior
          notice. Raseet may also impose limits on certain features and Services
          or restrict your access to parts of or all of the Site/App and the
          Services without notice or liability. The Site/App should not be used
          or relied upon for storage of your Bill Content and you are directed
          to retain your own copies of all Bill Content posted on the Site/App.
        </Section>
        <Section title="Registration">
          To fully use the services, you need to create your own account,
          without violating other people’s rights. You may not use as a username
          the name of another person or entity or that is not lawfully available
          for use, a name or trademark that is subject to any rights of another
          person or entity other than you without appropriate authorization, or
          a name that is otherwise offensive, vulgar, or obscene.
          <br />
          You are responsible for maintaining the confidentiality of your
          password and are solely responsible for all activities resulting from
          the use of your password and conducted through your Raseet account.
          <br />
          As a condition to using the Services, you are required to open an
          account with Raseet, select a password and username, and to provide
          registration information including a valid email address, which may be
          used to transfer bill information/payments to you for licensing Visual
          Content. The registration information you provide must be accurate,
          complete, and current at all times. Failure to do so constitutes a
          breach of the Terms, which may result in immediate termination of your
          access to the Services, by terminating your account. Any personal
          information that you provide to Raseet is governed by the Raseet
          Privacy Policy.
        </Section>
        <Section title="Raseet IP & Trademarks">
          Neither these Terms nor your use of the Services grants you ownership
          in the Services or the content you access through the Services (other
          than your Content). Except as permitted by Raseet's Brand and
          Trademark Use Policy, these Terms do not grant you any right to use
          Raseet's trademarks or other brand elements. If you submit any
          feedback or suggestions to us regarding our Services, we may use and
          share them for any purpose without any compensation or obligation to
          you.
          <br />
          Raseet, Kryptonas Innovations Private Limited and other Raseet
          graphics, logos, designs, page headers, button icons, scripts and
          service names are registered trademarks, trademarks of trade dress of
          Raseet. Raseet's trademarks and trade dress may not be used in
          connection with any product or service without the prior written
          consent of Raseet. The images and icons available in the Raseet press
          kit or icon pack may be used by partners and third party sites in
          connection with providing appropriate links to the Raseet website/app
          so long as they are used in a manner that is consistent with Raseet's
          requirements.
        </Section>
        <Section title="Use of service(s)/ User Conduct">
          As a condition of use, you promise not to use the services for any
          purpose that is unlawful or prohibited by these terms, or any other
          purpose not reasonably intended by Raseet. By way of example, and not
          as a limitation, you agree not to use the service:
          <br />
          <br />
          <ul>
            <li>
              To abuse, harass, threaten, impersonate or intimidate any person.
            </li>
            <li>
              To post or transmit, or cause to be posted or transmitted, any
              Content that is libellous, defamatory, obscene, pornographic,
              abusive, offensive, profane, or that infringes any copyright or
              other right of any person.
            </li>
            <li>
              To communicate with Raseet representatives or other users in an
              abusive or offensive manner.
            </li>
            <li>
              For any purpose (including posting or viewing Content) that is not
              permitted under the laws of the jurisdiction where you use the
              Services.
            </li>
            <li>
              To post or transmit, or cause to be posted or transmitted, any
              communication designed or intended to obtain password, account, or
              private information from any Raseet user.
            </li>
            <li>
              To create or transmit unwanted ‘spam’ to any person or any URL.
            </li>
            <li>
              To create multiple accounts for the purpose of voting for users’
              Bill Content.
            </li>
            <li>
              To post copyrighted Content that does not belong to you, unless
              you are commenting on Bill Content in Blogs, where you may post
              such Content subject to providing appropriate attribution to the
              copyright owner and a link to the source of the Content
            </li>
            <li>
              You agree not to use any robot, spider, scraper, or other
              automated means to access the Site for any purpose without our
              express written permission. Additionally, you agree that you will
              not: (i) take any action that imposes, or may impose in our sole
              discretion an unreasonable or disproportionately large load on our
              infrastructure; (ii) interfere or attempt to interfere with the
              proper working of the Site/App or any activities conducted on the
              Site/App, or (iii) bypass any measures we may use to prevent or
              restrict access to the Site/App.
            </li>
            <li>
              To advertise to, or solicit, any user to buy or sell any third
              party products or services, or to use any information obtained
              from the Services in order to contact, advertise to, solicit, or
              sell to any user without their prior explicit consent.
            </li>
            <li>
              To promote or sell Bill Content of another person unless you are
              expressly authorized to do so, or
            </li>
            <li>
              To sell, assign, or otherwise transfer your Profile or account.
            </li>
          </ul>
          <br />
          To report a suspected abuse of the Site or a breach of the Terms
          (other than relating to copyright infringement which is addressed
          under “COPYRIGHT COMPLAINTS” below), please send written notice to
          Raseet at email: support@raseet.com
          <br />
          <br />
          You are solely responsible for your interactions with other users of
          the Site/App. Raseet reserves the right, but has no obligation, to
          review disputes between you and other users. This includes the right
          to review messages exchanged through Raseet's service, based on any
          reports that Raseet receives alleging the violation of these Terms
          through the use of Raseet's service, including without limitation,
          reports regarding alleged harassment, indecency, and offensive
          messages.
          <br />
          <br />
          If the Services or Site/App are used in a manner that violates the
          Terms in any way, Raseet may at its sole discretion but is not
          required to, suspend or terminate your account, disable your access to
          the Site/App and/or take any steps that it deems appropriate to
          address the situation.
        </Section>
        <Section title="Fair Storage Policy">
          Raseet maintains a fair storage policy to ensure stable and fast
          service to all users. The storage limits set forth in the Terms may be
          changed from time to time without notice and you are responsible for
          complying with such changes and cost which we may introduce in future.
        </Section>
        <Section title="Links">
          The Services may provide, or third parties may provide, links to other
          sites or resources. Because Raseet has no control over such sites and
          resources, you acknowledge and agree that Raseet is not responsible
          for the availability of such external sites or resources, and does not
          endorse and is not responsible or liable for any content, advertising,
          products, or other materials on or available from such sites or
          resources. You further acknowledge and agree that Raseet shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with use of or
          reliance on any such content, goods, or services available on or
          through any such site or resource.
        </Section>
        <Section title="Release and Indemnity">
          You hereby expressly and irrevocably release and forever discharge
          Raseet, its affiliated and associated companies and its authorized
          distributors, and their respective directors, officers, employees,
          agents, representatives, independent and dependent contractors,
          licensees, successors and assigns of and from any and all actions,
          causes of action, suits, proceedings, liability, debts, judgments,
          claims, and demands whatsoever in law or equity which you ever had,
          now have, or hereafter can, shall or may have, for or by reason of, or
          arising directly or indirectly out of your use of the Site and the
          Services.
          <br />
          You hereby agree to indemnify and hold harmless Raseet, its affiliated
          and associated companies and its authorized distributors, and their
          respective directors, officers, employees, agents, representatives,
          independent and dependent contractors, licensees, successors and
          assigns from and against all claims, losses, expenses, damages and
          costs (including, but not limited to, direct, incidental,
          consequential, exemplary, and indirect damages), and reasonable
          attorneys' fees, resulting from or arising out of (i) a breach of
          these Terms, (ii) Content posted on the Site, (iii) the use of the
          Services or Premium Accounts, by you or any person using your account
          or Raseet Username and password/OTP, (iv) the licensing, distribution,
          or use of your Bill Content, or (v) any violation of any rights of a
          third party. Raseet reserves the right, at its own expense, to assume
          the exclusive defence and control of any matter otherwise subject to
          indemnification by you.
          <br />
          We are not liable if something goes really wrong. Always have a backup
          of your bills.
        </Section>
        <Section title="Termination">
          Raseet may terminate or suspend any and all Services and/or your
          Raseet account immediately, without prior notice or liability, for any
          reason whatsoever, including without limitation if you breach the
          Terms. Upon termination of your account, your right to use the
          Services will immediately cease. If you wish to terminate your Raseet
          account, you must do so in writing and it will result in the removal
          of your Bill Content from the Site pursuant to these Terms. All
          provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership and rights provisions and warranties, your entitlement to
          the payment of royalties, warranty disclaimers, indemnity and
          limitations of liability.
          <br />
          Upon termination (by Raseet or you), Raseet will remove your Bill
          Content within 180 days and will inform all distributors that the Bill
          Content should be removed during that time period, provided however
          that Raseet (and our distributors) may retain digital copies of Bill
          Content for archival and record-keeping purposes. Raseet will continue
          to make payments due to you after termination in accordance with these
          Terms. Raseet shall not be responsible for distributors who do not
          comply with Raseet instructions to remove Bill Content in a timely
          manner.
          <br />
          It is your responsibility to retain copies of your Bill Content
          because Raseet will automatically delete and remove all Bill Content
          from your account post-termination.
        </Section>
        <Section title="Disclaimer">
          YOUR USE OF THE SITE AND THE SERVICES IS AT YOUR OWN RISK. THE SITE
          AND THE SERVICES ARE PROVIDED ON AN “AS IS, AS AVAILABLE” BASIS.
          Raseet EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING THE WARRANTIES OF
          MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT. Raseet DISCLAIMS ALL RESPONSIBILITY FOR ANY LOSS,
          INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING FROM,
          ARISING OUT OF OR IN ANY WAY RELATED TO (A) ANY ERRORS IN OR OMISSIONS
          FROM THIS SITE/APP AND THE SERVICES, INCLUDING, BUT NOT LIMITED TO,
          TECHNICAL INACCURACIES AND TYPOGRAPHICAL ERRORS, (B) THIRD PARTY
          COMMUNICATIONS, (C) ANY THIRD PARTY WEBSITES OR CONTENT DIRECTLY OR
          INDIRECTLY ACCESSED THROUGH LINKS IN THE SITE, INCLUDING BUT NOT
          LIMITED TO ANY ERRORS OR OMISSIONS, (D) THE UNAVAILABILITY OF ALL OR
          ANY PART OF THE SITE OR THE SERVICES, (E) YOUR USE OF THE SITE OR THE
          SERVICES, OR (F) YOUR USE OF ANY EQUIPMENT OR SOFTWARE IN CONNECTION
          WITH THE SITE OR THE SERVICES.
        </Section>
        <Section title="Limitation of Liability">
          EXCEPT AS OTHERWISE PROVIDED BY LAW, NEITHER RASEET NOR ANY OF ITS
          AFFILIATES, PARENTS, SUBSIDIARIES, AUTHORIZED DISTRIBUTORS, DIRECTORS,
          SHAREHOLDERS, EMPLOYEES, CLIENTS, OR AGENTS SHALL BE LIABLE FOR ANY
          LOSS, INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING FROM
          YOUR USE OF THE SITE/APP, ANY FACTS OR OPINIONS APPEARING THEREON, OR
          THE SERVICES. RASEET SHALL NOT BE LIABLE FOR ANY SPECIAL, DIRECT,
          INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND
          WHATSOEVER (INCLUDING, WITHOUT LIMITATION, ATTORNEYS’ FEES) IN ANY WAY
          DUE TO, RESULTING FROM, OR ARISING IN CONNECTION WITH THE USE OF OR
          INABILITY TO USE THE SITE/APP OR SERVICES. TO THE EXTENT THE FOREGOING
          LIMITATION OF LIABILITY IS PROHIBITED OR FAILS OF ITS ESSENTIAL
          PURPOSE, RASEET'S SOLE OBLIGATION TO YOU FOR DAMAGES SHALL BE LIMITED
          TO (A) Rs1000.00 IF THE CLAIM IS UNRELATED TO THE LICENSING OF BILL
          CONTENT; OR (B) THE TOTAL FEES COLLECTED BY RASEET FOR THE BILL
          CONTENT THAT IS THE SUBJECT MATTER OF THE CLAIM IF THE CLAIM IS
          RELATED TO THE LICENSING OF BILL CONTENT, WITH THE UNDERSTANDING THAT
          Raseet IS NOT RESPONSIBLE FOR THE MISUSE OF BILL CONTENT BY
          DISTRIBUTORS OR ANY OTHER THIRD PARTY UNDER ANY CIRCUMSTANCES. {"/ >"}{" "}
          YOU AND RASEET AGREE THAT ANY PROCEEDINGS TO RESOLVE OR LITIGATE ANY
          DISPUTE ARISING HEREUNDER WILL BE CONDUCTED SOLELY ON AN INDIVIDUAL
          BASIS AND THAT YOU WILL NOT SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS
          ACTION, A REPRESENTATIVE ACTION, A COLLECTIVE ACTION, A PRIVATE
          ATTORNEY GENERAL ACTION, OR IN ANY PROCEEDING IN WHICH YOU ACT OR
          PROPOSE TO ACT IN A REPRESENTATIVE CAPACITY. YOU FURTHER AGREE THAT NO
          PROCEEDING WILL BE JOINED, CONSOLIDATED, OR COMBINED WITH ANOTHER
          PROCEEDING WITHOUT THE PRIOR WRITTEN CONSENT OF RASEET AND ALL PARTIES
          TO ANY SUCH PROCEEDING.
        </Section>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  termsAndCondtionsPage: {
    fontFamily: "Gotham Light",
    background: "#fff",
    height: "100vh",
  },
  backButton: {
    textAlign: "center",
    "& .anticon": {
      fontSize: 15,
    },
  },
  pageHeader: {
    fontSize: 15,
    fontWeight: 700,
  },
  header: {
    height: 60,
    position: "sticky",
    top: "0",
    background: "transparent",
    alignItems: "center",
  },
  pageContent: {
    height: "calc(100vh - 58px)",
    padding: 15,
    overflowY: "auto",
  },
  pageSection: {
    marginBottom: 50,
  },
  sectionTitle: {
    fontSize: 22,
    fontWeight: 600,
    marginBottom: 15,
  },
  sectionContent: {
    fontSize: 13,
    whiteSpace: "break-spaces",
    textAlign: "justify",

    "& ul": {
      paddingLeft: 30,
    },
  },
}));

export default TermsAndCondtions;
