import Typography from "antd/es/typography";
import ContentCard from "components/ContentCard";
import OurTeamMockup from "assets/images/our-team-mockup.png";
import { createUseStyles } from "react-jss";

export default function AboutUs() {
  const classes = useStyles();

  return (
    <div className={classes.pageContent}>
      <ContentCard titleCenter title="About Us" id="about-us">
        <Typography.Paragraph className={classes.contentCardParagraph}>
          Raseet is a platform that helps the local pharmacy to go online and
          function as an e-commerce. It helps the pharmacy become a
          one-stop-shop for their customer complete health and wellness needs.
          It empowers retailers to sell beyond medicines and earn passive
          income. They can do it by offering trusted healthcare and wellness
          services to the customers, such as doctor consultation, ambulance
          service, lab tests, supplements, health insurance, etc.
        </Typography.Paragraph>

        <Typography.Paragraph className={classes.contentCardParagraph}>
          Moreover, it helps in a better connection with the customers as it is
          a software that generates interactive mobile responsive digital bills
          which are messaged to the customer's phone. Here they get to directly
          contact each other. It also saves the cost incurred on printing paper
          bills.
        </Typography.Paragraph>
      </ContentCard>

      <ContentCard titleCenter title="Our Mission" id="our-mission">
        <Typography.Paragraph className={classes.contentCardParagraph}>
          Raseet is on the mission to make quality healthcare assistance
          available to everyone through their trusted pharmacist. While
          pharmacies struggle with stagnant revenues and low customer lifetime
          value being confined to a catchment, the plight of their customers are
          no less. An average Indian spends almost 65% of all healthcare
          expenses out-of-pocket, and yet struggles with finding trusted
          assistance when needed. We want to provide a turn-key technology
          platform to our pharmacies using which they can serve their customers
          beyond their traditional offering as well as provide them with timely
          and trust-worthy healthcare assistance.
        </Typography.Paragraph>
      </ContentCard>

      <ContentCard titleCenter title="Our Vision" id="our-vision">
        <Typography.Paragraph className={classes.contentCardParagraph}>
          At Raseet, we aim to empower the local pharmacists to match up with
          other e-pharmacy giants. Following the 'Vocal For Local' motto, we
          have made a platform where local pharmacists can take their business
          online and evolve into a one-stop-shop.
        </Typography.Paragraph>

        <Typography.Paragraph className={classes.contentCardParagraph}>
          We have partnered with a set of credible healthcare and wellness
          service providers who offer their products and services to customers
          over the pharmacy’s online store-front, allowing pharmacies to earn
          passively.
        </Typography.Paragraph>
      </ContentCard>

      <ContentCard titleCenter title="Our Team" id="our-team">
        <Typography.Paragraph className={classes.contentCardParagraph}>
          We have a team of dynamic personalities who come from India's most
          prestigious universities like IIT, IIM, MDI, NIT, Delhi University,
          Delhi Technical University, Jaypee University, Amity University, and
          Dr. APJ Abdul Kalam University.
        </Typography.Paragraph>

        <Typography.Paragraph className={classes.contentCardParagraph}>
          Their strong will and passion for their work have landed them in
          crucial positions in Amazon, Goldman Sachs, Aditya Birla Group,
          Amdocs, Newgen, ShopClues, Moglix, SportsKeeda, and TCS. So Raseet is
          made from their years of experience and expertise in this field.
        </Typography.Paragraph>

        <img
          src={OurTeamMockup}
          alt="our-team-mockup"
          className={classes.teamMockup}
        />
      </ContentCard>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  pageContent: {
    width: "100vw",
    background: "#F5F7FA",
    overflow: "hidden",
  },

  contentCardParagraph: {
    padding: [15, 10, 0],
    fontFamily: "Gotham Light",
    fontSize: 18,
    lineHeight: "36px",
    textAlign: "justify",
  },
  teamMockup: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    margin: [0],
  },

  "@media (min-width: 768px)": {
    pageContent: {
      width: "80vw",
      margin: "0 auto",
      padding: [50, 35],
    },
    contentCardParagraph: {
      padding: [15, 70, 0],
    },
  },
}));
