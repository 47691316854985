import { Col, Row } from "antd";
import ContentCard from "components/ContentCard";
import { FindUsAtData } from "data/ContactData";
import { createUseStyles } from "react-jss";

export default function FindUsAt() {
  const classes = useStyles();

  return (
    <ContentCard
      titleCenter
      title="Find Us At"
      className={classes.contentCardCustomization}
    >
      <Row className={classes.socialLinksContainer}>
        {FindUsAtData.map((item, index) => (
          <Col span={8} key={index} className={classes.socialLinkWrapper}>
            <a href={item.link} target="_blank" rel="noreferrer">
              <img
                src={item.icon}
                className={classes.socialLinkImg}
                alt={item.platform}
              />
            </a>
          </Col>
        ))}
      </Row>

      <div className={classes.bottomQuote}>
        <div className={classes.bottomQuoteText}>
          "When we are connected to others, we become better people."-
        </div>
        <div className={classes.bottomQuoteAuthor}>- Randy Pausch.</div>
      </div>
    </ContentCard>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  contentCardCustomization: {},
  socialLinksContainer: {
    padding: [10, 20],
  },
  socialLinkWrapper: {
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  socialLinkImg: {
    width: 90,
    objectFit: "contain",
  },

  bottomQuote: {
    padding: [30, 20, 0],
    fontSize: 18,
  },
  bottomQuoteText: {
    fontFamily: "Gotham Light",
  },
  bottomQuoteAuthor: {
    width: "100%",
    fontFamily: "Gotham Light",
    textAlign: "right",
  },

  "@media (min-width: 768px)": {
    socialLinksContainer: {
      padding: [10, 50],
    },
    bottomQuote: {
      padding: [30, 50, 0],
    },
  },
  "@media (min-width: 998px)": {
    contentCardCustomization: {
      height: "100%",
      marginBottom: 0,
    },
  },
}));
