import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Row } from "antd";
import ContentCard from "components/ContentCard";
import ErrorMessage from "components/ErrorMessage";
import { Form, Field, Formik } from "formik";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import ContactUsSchema from "schemas/ContactUsSchema";
import SubmitContactUs from "utils/SubmitContactUs";

export default function ContactUs() {
  const classes = useStyles();
  const [initialValues] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const commonInputProps = {
    as: Input,
    size: "large",
    className: classes.inputField,
  };

  async function onFormSubmit(values: any, { setSubmitting, resetForm }: any) {
    await SubmitContactUs({ ...values, number: `${values.number}` })
      .then((r: any) => {
        message.success(
          "We received you query, our team will reach you out soon.!"
        );
        resetForm();
        setSubmitting(false);
      })
      .catch((e) => console.log(e));
  }

  return (
    <ContentCard
      titleCenter
      title="Contact Us"
      className={classes.contentCardCustomization}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={ContactUsSchema}
        onSubmit={onFormSubmit}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <Row gutter={[15, 0]}>
              <Col xs={24} md={12}>
                <Field
                  {...commonInputProps}
                  as={Input}
                  name="name"
                  placeholder="Name"
                  prefix={<UserOutlined />}
                />
                <ErrorMessage
                  message={touched.name && errors.name}
                  className={classes.errMsg}
                />
              </Col>
              <Col xs={24} md={12}>
                <Field
                  {...commonInputProps}
                  name="number"
                  placeholder="Phone no."
                  prefix={<PhoneOutlined rotate={90} />}
                />
                <ErrorMessage
                  message={touched.number && errors.number}
                  className={classes.errMsg}
                />
              </Col>
            </Row>

            <Field
              {...commonInputProps}
              name="email"
              placeholder="Email"
              prefix={<MailOutlined />}
            />
            <ErrorMessage
              message={touched.email && errors.email}
              className={classes.errMsg}
            />

            <Field
              {...commonInputProps}
              rows={5}
              as={Input.TextArea}
              name="message"
              placeholder="Message"
            />
            <ErrorMessage
              message={touched.message && errors.message}
              className={classes.errMsg}
            />

            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              className={classes.submitBtn}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </ContentCard>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  contentCardCustomization: {},
  inputField: {
    fontFamily: "Gotham Light",
    marginTop: 15,
    borderRadius: 5,

    "& .anticon": {
      color: colors.dark400,
      paddingRight: 5,
    },
  },
  submitBtn: {
    marginTop: 20,
    fontSize: 14,
    fontFamily: "Gotham Medium",
    borderRadius: 5,
  },

  errMsg: {
    padding: [5, 5, 0],
    fontFamily: "Gotham Medium",
    fontSize: 10,
    color: "rgb(216, 118, 89)",
  },

  "@media (min-width: 998px)": {
    contentCardCustomization: {
      height: "100%",
      marginBottom: 0,
    },
  },
}));
