import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

function PrivacyPolicy() {
  const classes = useStyles();
  const history = useHistory();

  const Section = ({ title, children }: any) => (
    <div className={classes.pageSection}>
      <div className={classes.sectionTitle}>{title}</div>
      <div className={classes.sectionContent}>{children}</div>
    </div>
  );

  return (
    <div className={classes.privacyPolicyPage}>
      <Row className={classes.header}>
        <Col className={classes.backButton} xs={3} md={2} xl={1}>
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </Col>
        <Col className={classes.pageHeader} xs={20}>
          Privacy Policy
        </Col>
      </Row>
      <div className={classes.pageContent}>
        <div className={classes.pageSubtitle}>
          Raseet - a data digitization & remarketing platform
        </div>

        <Section title="Introduction">
          Raseet is a website/app owned and operated by Kryptonas Innovations
          Private Limited - a company duly registered and incorporated under the
          Companies Act, 1956, whose registered address is at 23B/17 Balrampur
          House, Prayagraj, 211002, Uttar Pradesh, India. Kryptonas Innovations
          Private Limited is strongly committed to protecting your privacy and
          has taken all necessary and reasonable measures to protect your
          personal information and handle the same safely and responsibly under
          the terms and conditions of this policy (“Privacy Policy”) set out
          herein below:
        </Section>
        <Section title="1. Collection of Personal Information:">
          For availing the Services/Products from the Website/App or to contact
          Raseet, you may have to sign up by giving the following personal
          information such as email address, name, phone number and address.
          Some of the information provided above is personally sensitive
          information (“Personal Information”) and are collected for
          verification of the identity of the user and to ensure due
          compliances. We do use your contact information to send you offers
          based on your previous bills and your interests. In general, you can
          browse the Website without telling us who you are or revealing any
          Personal Information about yourself. Additionally, if you browse
          through this Website, our servers may automatically record information
          of a certain kind. Such information includes information such as the
          name of the domain and host from which you access the Internet; the
          Internet Protocol (IP) address of the computer you are using; the date
          and time of your access. We use this information to monitor our
          website’s usage and whatever is necessary for our business. This
          information does not include Personal Information. If you choose to
          post messages on our message boards, chat rooms or other message areas
          or leave feedback, we will collect the information you provide to us.
          We retain this information, as necessary, to resolve disputes, provide
          customer support and troubleshoot problems as permitted by law. If you
          send us personal correspondence, such as emails or letters, or if
          other users or third parties send us correspondence about your
          activities or postings on the Website, we may collect such information
          into a file specific to you.
          <br />
          <br />
          Authorization via Gmail provides you with the option to share certain
          information with us, including your name, email address, and access to
          your email inbox and calendar in read-only mode, which you can revoke
          at any time. We may use this information for data analytics purposes
          but stay assured as your identity will remain highly anonymous.
        </Section>

        <Section title="2. Information collected from third parties:">
          We may collect your Personal Information as well as other information
          from third parties like business partners; contractors; etc and add
          the same to our account information.
        </Section>
        <Section title="3. Information placed on your computer:">
          We may store some information such as cookies on your computer.
          Cookies are pieces of information that an application transfers to the
          hard drive of a visitors computer for record-keeping purposes. This
          information facilitates your use of the Website/App and ensures that
          you do not need to re-enter your details every time you visit it. You
          can erase or choose to block this information from your computer if
          you want to. Erasing or blocking such information may limit the range
          of features available to the visitor on the Website. We also use such
          information to provide visitors with a personalized experience on the
          Website. We may use such information to allow visitors to use the
          website without logging in upon returning.
        </Section>
        <Section title="4. Information collected from your mobile device:">
          <b>(a) Personal Information:</b> All the information that we collect
          is related to providing Raseet services and features. We never receive
          any of the following information unless you expressly choose to share
          it with us. It’s as simple as that. Here are some references for the
          kind of information that we collect.
          <br />
          <b>(b) Phone State:</b> We access read-only information to phone state
          including phone number, device network. This allows us to provide you
          with a seamless experience and a higher calibre of customer service.
          <br />
          <b>(c) Location:</b> We will never gather or use your specific device
          location without first getting your explicit permission. It is used
          strictly to coordinate and complete your shipment for order delivery.
        </Section>
        <Section title="5. Links:">
          Our website/app may contain links to other sites. Such other sites may
          use information about your visit to this website/app. Our Privacy
          Policy does not apply to practices of such sites that we do not own or
          control or to people we do not employ. Therefore, we are not
          responsible for the privacy practices or the accuracy or the integrity
          of the content included on such other sites. We encourage you to read
          the individual privacy statements of such websites.
        </Section>
        <Section title="6. Use of Personal Information:">
          Website/App collects and uses your Personal Information to provide you
          better customer experience and also in the administration of our
          business. website/App may use your Personal Information to administer
          this service, personalize the Website services for you enable your
          access to and use of website/App services publish information about
          you on Website/App to keep you informed or update you on various
          products and services available from website/app send you statements
          and invoices collect payments from you send you marketing
          communications to send you emails or contact you for various customer
          satisfaction surveys, market research, promotional activities or in
          connection with certain transactions make communications necessary to
          notify you regarding various security, privacy, and administrative
          issues to respond to your queries send you information that may be of
          interest to you, share this information with Services/Products made
          available through Website to respond to any of your legitimate claim,
          or to address you if we have reasonable belief, that you are violating
          the rights of any third party or any of the agreements or policies
          that govern your use of Website Services/Products conduct research and
          perform analysis in order to measure, maintain, protect, develop and
          improve our services. The website has no operations in other countries
          and we do not transfer Personal Information to other countries.
          However, if the server space provider as a matter of backup or other
          general practises may transfer the server content between any of the
          countries in which the service provider operates or servers are
          located to enable the use of the information under this Privacy
          Policy, You agree to such cross-border transfers of Personal
          Information and the risk associated with it. Where Website discloses
          your Personal Information to any third party for any purpose mentioned
          above, such third parties are obligated to use that Personal
          Information under the terms of this Privacy Policy. In addition to the
          disclosures reasonably necessary for the purposes identified above,
          the Website may disclose your Personal Information to the extent that
          it is required to do so by law, in connection with any legal
          proceedings or prospective legal proceedings, and to establish,
          exercise or defend its legal rights. Raseet shall provide the services
          under the Agreement, either by itself or through any third party. In
          case the services are required to be provided through any third party,
          Raseet shall share the details (only to the extent required to provide
          the services) of the Customer to enable such third party/s to provide
          the service. The Customer hereby authorises Raseet to share the
          details of the Customer with such a third party.
        </Section>
        <Section title="6. Use of Personal Information:">
          Website/App collects and uses your Personal Information to provide you
          better customer experience and also in the administration of our
          business. website/App may use your Personal Information to administer
          this service, personalize the Website services for you enable your
          access to and use of website/App services publish information about
          you on Website/App to keep you informed or update you on various
          products and services available from website/app send you statements
          and invoices collect payments from you send you marketing
          communications to send you emails or contact you for various customer
          satisfaction surveys, market research, promotional activities or in
          connection with certain transactions make communications necessary to
          notify you regarding various security, privacy, and administrative
          issues to respond to your queries send you information that may be of
          interest to you, share this information with Services/Products made
          available through Website to respond to any of your legitimate claim,
          or to address you if we have reasonable belief, that you are violating
          the rights of any third party or any of the agreements or policies
          that govern your use of Website Services/Products conduct research and
          perform analysis in order to measure, maintain, protect, develop and
          improve our services. The website has no operations in other countries
          and we do not transfer Personal Information to other countries.
          However, if the server space provider as a matter of backup or other
          general practises may transfer the server content between any of the
          countries in which the service provider operates or servers are
          located to enable the use of the information under this Privacy
          Policy, You agree to such cross-border transfers of Personal
          Information and the risk associated with it. Where Website discloses
          your Personal Information to any third party for any purpose mentioned
          above, such third parties are obligated to use that Personal
          Information under the terms of this Privacy Policy. In addition to the
          disclosures reasonably necessary for the purposes identified above,
          the Website may disclose your Personal Information to the extent that
          it is required to do so by law, in connection with any legal
          proceedings or prospective legal proceedings, and to establish,
          exercise or defend its legal rights. Raseet shall provide the services
          under the Agreement, either by itself or through any third party. In
          case the services are required to be provided through any third party,
          Raseet shall share the details (only to the extent required to provide
          the services) of the Customer to enable such third party/s to provide
          the service. The Customer hereby authorises Raseet to share the
          details of the Customer with such a third party.
        </Section>
        <Section title="7. Personal Information that we share:">
          We are committed to protecting the privacy and security of your
          information. At no time will we sell your Personal Information without
          your permission unless outlined in this Privacy Policy to third
          parties. The information we receive about you or from you may be used
          by us or shared by us with our corporate affiliates, dealers, agents,
          vendors and other third parties. We do not share, sell, trade or rent
          your Personal Information to third parties for unknown reasons. We
          retain complete anonymity while in all analytics and none of the
          Personal Information is used, except in a limited set of circumstances
          as stated below. We safeguard your email addresses. We don't sell the
          email addresses provided by you and we use them only as directed by
          you and under this Policy.
        </Section>
        <Section title="8. We share your Personal Information as described below:">
          Business Transfers: If we start up subsidiaries or get involved in
          mergers or acquisitions. In such cases, your Personal Information may
          be a matter of transfer. And we will provide notice on any such
          transfer and become subject to different privacy policies.
        </Section>
        <Section title="9. DND Waiver:">
          You agree and authorize the Company to use and share your information
          with its contractors and other third parties, in so far as required
          for joint marketing purposes/offering various services/report
          generations and/or to similar services to provide you with various
          value-added services, in association with the Services selected by you
          or otherwise. You agree to receive communications through emails,
          telephone and/or SMS, from the Company including its contractors or
          its third-party vendors regarding the Services/ancillary services
          updates, information/promotional emails and/or product announcements.
          In this context, you agree and consent to receive all communications
          at the mobile number provided, even if this mobile number is
          registered under DND/NCPR list under TRAI regulations, and for such
          purpose, you further authorize Company to share/disclose the
          information to its contractors or any third-party service provider.
        </Section>
        <Section title="10. Our commitment to Personal Information security:">
          We recognize that your privacy is important to you, and therefore we
          endeavour to keep your Personal Information confidential. We will take
          reasonable technical and organizational precautions to prevent the
          loss, misuse or alteration of your Personal Information. We assure you
          of our best effort to protect Personal Information, HOWEVER, WE DO NOT
          REPRESENT, WARRANT, OR GUARANTEE THAT YOUR PERSONAL INFORMATION WILL
          BE PROTECTED AGAINST UNAUTHORIZED ACCESS, LOSS, MISUSE, OR
          ALTERATIONS, AND DO NOT ACCEPT ANY LIABILITY FOR THE SECURITY OF THE
          PERSONAL INFORMATION SUBMITTED TO US NOR FOR YOUR OR THIRD PARTIES USE
          OR MISUSE OF PERSONAL INFORMATION.
        </Section>
        <Section title="11. Payment Gateway:">
          Information relating to electronic transactions entered into via the
          website/app shall be protected by encryption technology. We have
          partnered with secure payment gateways i.e. Paytm gateway and/or
          Razorpay and/or Instamojo. The Website cannot interfere and do not
          interfere with the payment gateway mechanism. The website/app has no
          access to the information you may enter to make the payment through
          the payment gateway. Your transaction and banking details or other
          information as required for internet banking or other payment
          instruments is held by our Payment Gateway partner. By creating a link
          to a payment gateway, we do not endorse the payment gateway, nor are
          we liable for any failure of products or services offered by such
          payment gateway. Such a payment gateway may have a privacy policy
          different from ours. All failures/ errors/ omissions of the payment
          gateway shall be solely on the payment gateway. You hereby consent
          that you shall not sue the Website for any disputes that you may have
          with the payment gateway for any wrongdoing of the payment gateway.
        </Section>
        <Section title="12. Security:">
          We safeguard your privacy using known security standards and
          procedures and comply with applicable privacy laws. Our website/app
          combines industry-approved physical, electronic and procedural
          safeguards to ensure that your information is well protected through
          its life cycle in our infrastructure. Personal Information is hashed
          or encrypted when it is stored in our infrastructure. Personal
          Information is decrypted, processed and immediately re-encrypted or
          discarded when no longer necessary.
        </Section>
        <Section title="13. Opt-Out Policy:">
          Please email us at support@raseet.com if you no longer wish to receive
          any information from us.
        </Section>
        <Section title="14. Changes to the Privacy Policy:">
          We reserve the right to modify this Privacy Policy and it is subject
          to change at any time without notice. If we decide to change our
          Privacy Policy, such changes will be posted on the website so that you
          are always aware of the latest amendments in this Privacy Policy and
          please review this policy periodically.
        </Section>
        <Section title="15. About this Privacy Policy:">
          If you choose to visit our website/app, your visit and any dispute
          over privacy are subject to this Privacy Policy and Terms of Use. The
          terms of this Privacy Policy do not govern Personal Information
          furnished through any means other than the Website. If you have any
          questions about this Privacy Policy or Website treatment of your
          Personal Information, please write to support@raseet.com
        </Section>
        <Section title="16. Grievances:">
          If you have any grievances against the website/app privacy practice or
          apprehend that your privacy is compromised at the website/app, please
          address your complaint/ concerns to support@raseet.com. We assure you
          that we shall proactively address your concerns. All disputes between
          us and you in this regard are subject to the exclusive jurisdiction of
          the Prayagraj (erstwhile Allahabad) High court.
        </Section>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  privacyPolicyPage: {
    fontFamily: "Gotham Light",
    background: "#fff",
    height: "100vh",
  },
  backButton: {
    textAlign: "center",
    "& .anticon": {
      fontSize: 15,
    },
  },
  pageHeader: {
    fontSize: 15,
    fontWeight: 700,
  },
  header: {
    height: 60,
    position: "sticky",
    top: "0",
    background: "transparent",
    alignItems: "center",
  },
  pageContent: {
    height: "calc(100vh - 58px)",
    padding: 15,
    overflowY: "auto",
  },
  pageSection: {
    marginBottom: 50,
  },
  pageSubtitle: {
    fontSize: 14,
    fontWeight: 600,
    margin: [0, 0, 30],
  },
  sectionTitle: {
    fontSize: 22,
    fontWeight: 600,
    marginBottom: 15,
  },
  sectionContent: {
    fontSize: 13,
    whiteSpace: "break-spaces",
    textAlign: "justify",

    "& b": {
      fontWeight: "500 !important",
    },
    "& ul": {
      paddingLeft: 30,
    },
  },
}));

export default PrivacyPolicy;
