import * as Yup from "yup";

const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  number: Yup.string()
    .length(10, "Mobile number must be 10 digits")
    .required("This field is required"),
  email: Yup.string().email("Invalid Email").required("This field is required"),
  message: Yup.string().required("This field is required"),
});

export default ContactUsSchema;
