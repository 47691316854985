import Card from "antd/es/card";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Typography from "antd/es/typography";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import PharmaChainImg from "assets/images/pharma-chain.svg";
import { createUseStyles } from "react-jss";

export default function WhatIsRaseet() {
  const classes = useStyles();
  const isMD = useBreakpoint().md;

  return (
    <Card className={classes.contentCard} id="what-is-raseet">
      <Row>
        <Col xs={24} lg={12}>
          <Typography.Title
            level={isMD ? 1 : 2}
            className={classes.contentCardTitle}
          >
            What is Raseet?
          </Typography.Title>

          <Typography.Paragraph className={classes.contentCardParagraph}>
            Raseet is a software which helps local pharmacies to go online and
            function as an e-commerce. It helps the pharmacy become a
            one-stop-shop for their customers' complete health &#38; wellness
            needs. And it does all of this by just sending a digital bill to the
            end customers!
          </Typography.Paragraph>
          <Typography.Paragraph className={classes.contentCardParagraph}>
            The digital bill is preloaded with a fully functional e-store,
            complete with custom offers &#38; promotions, online payment
            integrations, loyalty programs, customer &#38; business insights,
            healthcare and wellness service providers, and a lot more.
          </Typography.Paragraph>
        </Col>
        <Col xs={24} lg={12}>
          <img
            src={PharmaChainImg}
            alt="Pharmacy chain"
            className={classes.contentCardImageFull}
          />
        </Col>
      </Row>
    </Card>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  contentCard: {
    borderRadius: "1.5rem",
    padding: [40, 10, 20],
    marginBottom: 50,
    background: colors.light100,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  contentCardTitle: {
    color: "#236887 !important",
    fontFamily: "Gotham Bold",
  },
  contentCardParagraph: {
    padding: [0, 10],
    fontFamily: "Gotham Light",
    fontSize: 20,
    lineHeight: "36px",
  },
  contentCardImageFull: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  "@media (min-width: 768px)": {
    contentCard: {
      padding: [40, 10, 20],
      marginBottom: 100,
    },
  },
}));
