import { Collapse } from "antd";
import { createUseStyles } from "react-jss";
const { Panel } = Collapse;

type CollapsibleQuestionProps = {
  question: string;
  answer: any;
};

function CollapsibleQuestion({ question, answer }: CollapsibleQuestionProps) {
  const classes = useStyles();

  return (
    <Collapse ghost bordered={false} className={classes.collapsibleQuestion}>
      <Panel header={<span>{question}</span>} key="key">
        <p style={{ paddingLeft: 35 }}>{answer}</p>
      </Panel>
    </Collapse>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  collapsibleQuestion: {
    padding: [0, 0, 12],
    "& .anticon": {
      paddingTop: 6,
      color: "#E76F51",
      fontSize: "15px !important",
      marginRight: "20px !important",
    },

    "& .ant-collapse-header": {
      paddingBottom: "0 !important",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "15px",
      fontFamily: "'Roboto'",
      letterSpacing: "0.03em",
      color: "#2D2C2C",
      display: "flex",
      justifyContent: "flex-start",
    },

    "& .ant-collapse-content-box": {
      paddingBottom: "0 !important",
      fontFamily: "Gotham Light",
      fontSize: 16,
      fontWeight: 100,
      lineHeight: "25px",
      letterSpacing: "0.03em",
      color: "#2D2C2C",
    },
  },
}));

export default CollapsibleQuestion;
