import { BrowserRouter as Router } from "react-router-dom";
import theme from "./constants/theme";
import { ThemeProvider } from "react-jss";
import App from "./App";

function Root() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Router>
  );
}

export default Root;
