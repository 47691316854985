import Col from "antd/es/col";
import Row from "antd/es/row";
import ContentCard from "components/ContentCard";
import { Testimonials } from "data/ContentData";
import { createUseStyles } from "react-jss";

export default function Testimoinials() {
  const classes = useStyles();

  return (
    <ContentCard title="Testimoinials">
      <Row gutter={30}>
        {Testimonials.map((item, index) => (
          <Col
            key={index}
            xs={24}
            md={12}
            lg={8}
            className={classes.testimonialCard}
          >
            <div className={classes.testimonialCardHead}>
              <img src={item.img} alt={item.name} />
              <div>
                <div className={classes.testimonialClientName}>{item.name}</div>
                <div className={classes.testimonialClientLocation}>
                  {item.location}
                </div>
              </div>
            </div>

            <div className={classes.testimonialClientContent}>
              {item.content}
            </div>
          </Col>
        ))}
      </Row>
    </ContentCard>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  contentCard: {
    borderRadius: "1.5rem",
    padding: [40, 10, 20],
    marginBottom: 150,
    background: colors.light100,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  contentCardTitle: {
    color: "#236887 !important",
    fontFamily: "Gotham Bold",
  },

  testimonialCard: {
    padding: [15, 0],
  },
  testimonialCardHead: {
    display: "flex",
    alignItems: "center",
  },
  testimonialClientName: {
    fontFamily: "Gotham Medium",
    color: "#236887",
    fontSize: 14,
  },
  testimonialClientLocation: {
    fontFamily: "Gotham Light",
    color: "#000000",
    fontSize: 14,
  },
  testimonialClientContent: {
    padding: [0, 20],
    fontFamily: "Gotham Light",
  },
}));
