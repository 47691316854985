import Button from "antd/es/button";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Typography from "antd/es/typography";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import ContentCard from "components/ContentCard";
import { WhyUseRaseetData } from "data/ContentData";
import { useState } from "react";
import { createUseStyles } from "react-jss";

export default function WhyUseRaseet() {
  const classes = useStyles();
  const isXL = useBreakpoint().xl;
  const [viewMore, setViewMore] = useState(false);

  return (
    <ContentCard title="Why Use Raseet?" id="why-use-raseet">
      <Row
        gutter={isXL ? [50, 30] : [40, 30]}
        className={classes.gridInfoContainer}
      >
        {WhyUseRaseetData.slice(0, viewMore ? 9 : 6).map((item, index) => (
          <Col
            xs={24}
            md={12}
            lg={8}
            key={index}
            className={classes.gridInfoItem}
          >
            <img src={item.icon} alt={item.title} />
            <Typography.Title level={3} className={classes.gridInfoTitle}>
              {item.title}
            </Typography.Title>
            <Typography.Paragraph className={classes.gridInfoContent}>
              {item.content}
            </Typography.Paragraph>
          </Col>
        ))}
      </Row>

      <Button
        block
        type="link"
        size="large"
        onClick={() => setViewMore(!viewMore)}
      >
        {viewMore ? "View less" : "View more"}
      </Button>
    </ContentCard>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  contentCard: {
    borderRadius: "1.5rem",
    padding: [40, 10, 20],
    marginBottom: 150,
    background: colors.light100,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  contentCardTitle: {
    color: "#236887 !important",
    fontFamily: "Gotham Bold",
  },
  gridInfoContainer: {
    padding: [50, 0, 30],
  },
  gridInfoItem: {
    // padding: [40, 20, 0],
  },
  gridInfoTitle: {
    fontFamily: "Gotham Medium",
    fontSize: 28,
    lineHeight: "31px",
    color: "#236887 !important",
  },
  gridInfoContent: {
    fontFamily: "Gotham Light",
    fontSize: 16,
    lineHeight: "20px",
  },
}));
