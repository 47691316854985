import Icons04 from "assets/icons/icons-04.svg";
import Icons09 from "assets/icons/icons-09.svg";
import Icons18 from "assets/icons/icons-18.svg";
import Icons20 from "assets/icons/icons-20.svg";
import Icons19 from "assets/icons/icons-19.svg";
import Icons24 from "assets/icons/icons-24.svg";

export const FindUsAtData = [
  {
    icon: Icons20,
    platform: "facebook",
    link: "https://www.facebook.com/raseethq/",
  },
  {
    icon: Icons09,
    platform: "instagram",
    link: "https://instagram.com/raseethq",
  },
  {
    icon: Icons04,
    platform: "quora",
    link: "https://www.quora.com/profile/Raseet",
  },
  {
    icon: Icons24,
    platform: "linkedin",
    link: "https://www.linkedin.com/company/kryptonas/",
  },
  {
    icon: Icons18,
    platform: "youtube",
    link: "https://youtube.com/channel/UCUFe6SlrMadOqkP49PY4rDw",
  },
  { icon: Icons19, platform: "twitter", link: "https://twitter.com/RaseetHQ" },
];
