import FAQs from "components/sections/FAQs";
import { createUseStyles } from "react-jss";

export default function FAQsPage() {
  const classes = useStyles();

  return (
    <div className={classes.pageContent}>
      <FAQs
        expanded
        cardStyles={{ borderRadius: 0, boxShadow: "none", marginBottom: 0 }}
      />
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  pageContent: {
    background: "#F5F7FA",
  },

  "@media (min-width: 768px)": {
    pageContent: {
      width: "80vw",
      margin: "0 auto",
      padding: [0, 35],
    },
  },
}));
